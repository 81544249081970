




$(document).ready(function() {

  $('.js-slider').slick({
    // setting-name: setting-value
    dots: true,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    // centerMode: true,
  });




  // HEADER - menu
  $('.menu-mob-btn').on('click', function() {
    console.log( 'ok' );
    $('.menu').fadeToggle();
  });



  // Липкий хедер - инициализировать зависимость
  var sticky = new Waypoint.Sticky({
    element: $('#menu')[0]
  });

  // МОДАЛЬНЫЕ ОКНА
  // показать
  $('[data-modal-id-btn]').on('click', function() {
    let id = $(this).attr('data-modal-id-btn');
    $(`[data-modal-id="${id}"]`).show();
  });

  // закрыть
  $('.modal__close-btn').on('click', function() {
    $(this).parents('.modal-overlay').hide();
  });
  
  // закрыть
  $('.cf__close').on('click', function() {
    $(this).parents('.modal-overlay').hide();
  });

});





        




